import * as Yup from 'yup';

export const registerValidation = Yup.object().shape({
  provision_for: Yup.string().required('validations.shouldNotBeEmpty'),
  first_name: Yup.string().required('validations.user.first_name'),
  last_name: Yup.string().required('validations.user.last_name'),

  mobile_phone: Yup.string().when('first_name', {
    is: (mobile_phone: string) => mobile_phone.length > 0,
    then: (schema) =>
      schema
        .required('validations.user.mobile_phone.required')
        .max(22, 'validations.user.mobile_phone.max')
        .matches(/^[0-9\s+]+$/, 'validations.user.mobile_phone.matches')
        .min(7, 'validations.user.mobile_phone.min'),
  }),

  civil_status: Yup.string().required('validations.user.civil_status'),
  number_of_children: Yup.number()
    .required('validations.shouldNotBeEmpty')
    .typeError('validations.must_be_number'),
  email: Yup.string().email('Invalid email').required('validations.user.email'),
  gender: Yup.string().required('validations.user.gender'),
  date_of_birth: Yup.date()
    .typeError('validations.invalid_date')
    .required('validations.user.date_of_birth.required')
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      'validations.user.date_of_birth.max'
    ),
  have_real_estate: Yup.string().required('validations.shouldNotBeEmpty'),
});

export const initialValues = (currentLang: string) => ({
  provision_for: import.meta.env.VITE_REGISTER_PROVISION_FOR || '',
  date_of_birth: import.meta.env.VITE_REGISTER_DATE_OF_BIRTH || null,
  first_name: import.meta.env.VITE_REGISTER_FIRST_NAME || '',
  last_name: import.meta.env.VITE_REGISTER_LAST_NAME || '',
  email: '',
  mobile_phone: import.meta.env.VITE_REGISTER_MOBILE_PHONE || '',
  civil_status: import.meta.env.VITE_REGISTER_CIVIL_STATUS || '',
  reminder_frequency: 'disabled',
  number_of_children: import.meta.env.VITE_REGISTER_NUMBER_OF_CHILDREN || null,
  have_real_estate: import.meta.env.VITE_REGISTER_HAVE_REAL_ESTATE || '',
  gender: import.meta.env.VITE_REGISTER_GENDER || '',
  language: currentLang,
});
